<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="600px"  @close="close">
    <!-- 所属用户 -->
    <el-form v-if="dialogFormVisible" ref="form" :model="form" :rules="rules" label-width="125px">
      <el-form-item label="所属用户">
        <div style="display: flex; align-items: cetner;">
          <el-select ref="select" v-model="form.uid" filterable remote reserve-keyword placeholder="请输入关键词"
            :remote-method="remoteMethod" @focus="clear" @hook:mounted="clear" @visible-change="clear" @change="getInfo">
            <el-option v-for="item in options" :key="item.id" :label="`${item.nickname} （${item.code ? item.code : '-'}）`"
              :value="item.id"></el-option>
          </el-select>
          <el-button type="primary" @click="clearUserInfo" size="mini" style="margin-left: 15px;" >清除历史记录</el-button>
        </div>

      </el-form-item>
      <!-- 所属状态 -->
      <el-form-item label="所属状态" prop="status">
        <el-select v-model="form.status" placeholder="请选择">
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!-- 仓库所在区 -->
      <el-form-item v-if="showCangku" label="仓库所在区" prop="type">
        <el-select v-model="form.type" placeholder="请选择">
          <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号" prop="code">
        <el-input v-if="title == '添加'" v-model.trim="form.code" autocomplete="off"></el-input>
        <el-input v-else v-model.trim="form.code" :disabled="isShowCode" autocomplete="off"></el-input>
      </el-form-item>
      <!-- 运单号 -->
      <el-form-item label="运单编号" prop="express_code">
        <el-input v-model.trim="form.express_code" autocomplete="off"></el-input>
      </el-form-item>
      <!-- 重量 -->
      <el-form-item label="订单重量" prop="weight">
        <el-input v-model.trim="form.weight" autocomplete="off" maxlength="8" show-word-limit
          @input="handleInputWeight"></el-input>
      </el-form-item>
      <!-- 价格 -->
      <el-form-item label="订单价格" prop="price">
        <el-input v-model.trim="form.price" autocomplete="off" maxlength="8" show-word-limit
          @input="handleInputPrice"></el-input>
      </el-form-item>
      <el-form-item label="收货人" prop="name">
        <el-input v-model.trim="form.name" autocomplete="off" maxlength="15" show-word-limit
          @input="handleInputPrice"></el-input>
      </el-form-item>
      <el-form-item label="收货人电话" prop="phone">
        <el-input v-model.trim="form.phone" autocomplete="off" maxlength="11" show-word-limit
          @input="handleInputPrice"></el-input>
      </el-form-item>
      <!-- 收货地址 -->
      <el-form-item label="收货人所在国家" prop="">
          <!-- <City-selection :info="form"></City-selection> -->
          <!-- <el-cascader @change="choosecity" :placeholder="city_text" :props="props"></el-cascader> -->
          <el-select v-model="form.country_id" placeholder="请选择">
              <el-option
                v-for="item in country_List"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
      </el-form-item>
      <el-form-item label="收货人所在城市" prop="">
        <el-input v-model.trim="form.city" autocomplete="off" maxlength="18" show-word-limit ></el-input>
      </el-form-item>
      <el-form-item label="收货人详细地址" prop="address">
        <el-input v-model.trim="form.address" autocomplete="off" maxlength="50" show-word-limit ></el-input>
      </el-form-item>
      <el-form-item label="发货人" prop="">
        <el-input v-model.trim="form.sender_name" autocomplete="off" maxlength="11" show-word-limit
         ></el-input>
      </el-form-item>
      <el-form-item label="发货人电话" prop="">
        <el-input v-model.trim="form.sender_phone" autocomplete="off" maxlength="11" show-word-limit
          ></el-input>
      </el-form-item>
      <el-form-item label="发货人地址" prop="">
        <el-input v-model.trim="form.sender_address" autocomplete="off" maxlength="11" show-word-limit
        ></el-input>
      </el-form-item>


      <!-- 产品图片 -->
      <el-form-item label="包裹图片" prop="pic">
        <uploadFile :limit="1" :img-list-string="form.pic" @fileChange="uploadChange($event, 'pic')" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    orderDetail,
    memberListAll,
    orderUpdate,
    CountryList
  } from '@/api/xgcApi'
  import {
    getUploadUrl
  } from '@/config'
  import uploadFile from '@/components/uploadFile'
  // import CitySelection from '@/components/CitySelection'
  import {
    goodsAdd,
    index_city
  } from '../../../api/xgcApi'
  import {
    number
  } from 'echarts'

  export default {
    name: 'UserManagementEdit',
    components: {
      uploadFile,
    },
    data() {
      return {
        country_List:[],
        props: {
          lazy: true,
          lazyLoad:this.getcityList
        },
        city_text:'-',
        loading: false,
        options: [],
        typeList: [{
            label: '韩区包裹',
            value: 1
          },
          {
            label: '日区包裹',
            value: 2
          },
        ],
        statusList: [{
            value: 0,
            label: '未入库',
          },
          {
            value: 1,
            label: '已入库',
          },
          {
            value: 2,
            label: '待审核',
          },
          {
            value: 3,
            label: '待打包',
          },
          {
            value: 4,
            label: '待发货',
          },
          {
            value: 5,
            label: '待支付',
          },
          {
            value: 6,
            label: '转运中',
          },
          {
            value: 7,
            label: '已签收',
          },
          {
            value: 8,
            label: '已拒绝',
          },
          {
            value: 10,
            label: '暂存',
          },
          {
            value: 11,
            label: '退款中',
          },
          {
            value: 12,
            label: '已退款',
          },
        ],
        showCangku: false,
        form: {
          uid: '',
          status: ''
        },
        dialogFormVisible: false,
        rules: {
          type: [{
            required: true,
            trigger: 'blur',
            message: '请选择仓库所在区'
          }, ],
          code: [{
            required: true,
            trigger: 'blur',
            message: '请输入包裹单号'
          }, ],
          uid: [{
            required: true,
            trigger: 'change',
            message: '请选择所属用户'
          }, ],
        },
        title: '',
        isShowCode: false,
      }
    },

    mounted() {
      this.getCountryList()
    },
    methods: {
      getCountryList(){

        CountryList({}).then(res=>{
          this.country_List = res.data.list
        })
      },
      clearUserInfo(){
          localStorage.setItem('userUid', null)
          localStorage.setItem('userInfo', null)
          this.form.uid = null
      },
      choosecity(e){
        console.log(e , '当前选中的省市区')
        this.form.province = e[0]
        this.form.city = e[1]
        this.form.area = e[2]
      },
      // 获取省事区
      getcityList(node, resolve){
        const { level } = node;
        const { data } = node;
        var gid=0
        if(data){
          gid = data.value
        }else{
          gid = 0
        }
        index_city({pid:gid}).then(res=>{
          const nodes = Array.from(res.data, item => ({
              value: item.code,
              label: item.name,
              leaf: level >= 2,//是否有子选项
          }))
          resolve(nodes);
        })
      },
      // 解决移动端，点击不显示键盘问题
      // 调起移动端键盘
      clear(async) {
        this.$nextTick(() => {
          if (!async) {
            // ios 手机有延迟问题
            setTimeout(() => {
              const {
                select
              } = this.$refs
              const input = select.$el.querySelector('.el-input__inner')
              input.removeAttribute('readonly')
            }, 200)
          }
        })
      },
      // // 解决ios移动端选择双击问题
      // recordTouchStart(event) {
      //   const u = navigator.userAgent
      //   const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      //   if (isiOS && this._isMobile()) {
      //     event.visible = true //only work in iOS
      //   }
      // },
      // _isMobile() {
      //   return navigator.userAgent.match(
      //     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      //   )
      // },

      // 用户筛选
      remoteMethod(e) {
        this.page = 1
        this.limit = 20
        var data = {
          nickname: e,
          page: 1,
          limit: 100,
        }
        memberListAll(data).then(({
          data
        }) => {
          this.options = data
        })
      },
      // 重量输入校验
      handleInputWeight() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.weight = this.form.weight
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      // 价格输入校验
      handleInputPrice() {
        // 移除非数字和非小数点字符，保留小数点
        this.form.weight = this.form.weight
        this.form.price = this.form.price
          .replace(/[^\d.]/g, '')
          // 保留第一个小数点，移除其他小数点
          .replace(/^(\d*\.)(.*)\./g, '$1$2')
          // 移除前导零，但保留小数点前的单个零
          .replace(/^0+(\d+\.{0,1}\d*)/, '$1')
      },
      // 上传图片
      uploadChange(e) {
        var strurl = e.join(',')
        strurl = strurl.replace('\\\\', '\/\/')
        strurl = strurl.replace('\\', '\/')
        strurl = strurl.replace('\\', '\/')
        this.form.pic = strurl
      },
      // 初始化请求数据
      // country，type  1=韩国，2=日本
      async showEdit(row) {
        this.loading = true
        if (row.country == 1) {
          this.title = '添加'
          this.form.type = '1'
          this.loadFromCache()
          this.getUserInfo()
        } else if (row.country == 2) {
          this.title = '添加'
          this.form.type = '2'
          this.loadFromCache()
          this.getUserInfo()
        } else {
          this.title = '订单编辑'
          this.form.type = row.type
          if (
            row.status == 4 ||
            row.status == 5 ||
            row.status == 6 ||
            row.status == 7 ||
            row.status == 10
          ) {
            this.isShowCode = true
          } else {
            this.isShowCode = false
          }
          orderDetail({
            id: row.id
          }).then(({
            data
          }) => {
            this.form = data
            if(data.province_name){
              this.city_text = `${data.province_name}/${data.city_name}/${data.area_name}`
            }else{
              this.city_text = '请选择省市区'
            }

            if (data.userInfo) {
              this.form.uid = data.userInfo.id ? data.userInfo.id : ''
              this.getUserInfo({})
            } else {
              this.getUserInfo({})
            }
            if (this.form.type == 0) {
              this.form.type = null
            }
          })
        }
        this.dialogFormVisible = true
      },
      // 获取选中的所属用户信息
      getInfo(value) {
        this.options.forEach((item, index) => {
          if (value == item.id) {
            localStorage.setItem('userUid', item.id)
            localStorage.setItem('userInfo', JSON.stringify(item))
          }
        })
      },
      // 获取用户全量昵称，code
      async getUserInfo(e) {
        var data = {
          ...e,
          page: 1,
          limit: 100,
        }
        memberListAll(data).then(({
          data
        }) => {
          if (this.form.userInfo) {
            var a = JSON.stringify(data).indexOf(this.form.userInfo.code)
            if (a < 0) {
              data.push(this.form.userInfo)
            }
          }
          this.options = data
          this.loading = false
        })
      },
      // 本地缓存中取uid
      loadFromCache() {
        if (localStorage.getItem('userUid')) {
          let numObj = Number(localStorage.getItem('userUid'))
          this.form.uid = numObj
          this.form.userInfo = JSON.parse(localStorage.getItem('userInfo'))
        } else {
          this.form.uid = ''
          this.form.userInfo = null
        }
      },
      // 关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        for (var key in this.form) {
          this.form[key] = null
        }
        this.dialogFormVisible = false
      },

      // 提交修改//修改传ID  添加不传ID
      save() {
        var setDate = {
          id: this.form.id, //修改传 添加不传
          uid: this.form.uid ? this.form.uid : null,
          type: this.form.type,
          status: this.form.status || '',
          pic: this.form.pic,
          code: this.form.code,
          express_code: this.form.express_code,
          weight: this.form.weight,
          price: this.form.price,
          province: this.form.province,
          city: this.form.city,
          area: this.form.area,
          address: this.form.address,
          name: this.form.name,
          phone: this.form.phone,
          idcode: this.form.idcode,
          country_id:this.form.country_id,
          sender_address:this.form.sender_address,
          sender_name:this.form.sender_name,
          sender_phone:this.form.sender_phone,
        }
        console.log(setDate , '--------')
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            const {
              data,
              msg
            } = await orderUpdate(setDate)
            this.$baseMessage(msg, 'success')
            this.$emit('fetch-data')
            this.close()
          } else {
            return false
          }
        })
      },
    },
  }
</script>
<style scoped lang="scss">
  :deep .el-input--small .el-input__inner {
  }

  // :deep .el-scrollbar .el-scrollbar__bar {
  //   opacity: 1 !important;
  // }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>
