<template>
  <div v-loading="listLoading" class="table-container">
    <vab-query-form>
      <el-form ref="form" :model="queryForm" :inline="true" @submit.native.prevent>
        <el-form-item style="float: right">
          <el-button icon="el-icon-plus" type="primary" @click="handleAdd(true)">
            添加国家
          </el-button>
        </el-form-item>
      </el-form>
    </vab-query-form>

    <el-table ref="table" :data="list" height="75vh" border>
      <el-table-column align="center" prop="name" label="标题" min-width="180"></el-table-column>
      <el-table-column align="center" prop="sort" label="排序" min-width="180"></el-table-column>
      <el-table-column align="center" prop="code" label="编号" min-width="180"></el-table-column>
      <el-table-column align="center" prop="add_time" label="添加时间" min-width="180"></el-table-column>
      <el-table-column align="center" label="操作" min-width="180">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleAdd(false, row)">修改</el-button>
          <el-button type="text" @click="handleDel(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加修改侧边栏对话框 -->
    <el-dialog :title="IsAdd ? '添加国家' : '修改国家'" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <el-form v-if="dialogVisible" ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px"
        class="demo-ruleForm">
        <el-form-item label="国家" prop="name">
          <el-input maxlength="20" v-model="ruleForm.name" placeholder="请输入国家"></el-input>
        </el-form-item>
        <el-form-item label="国家编码" prop="code">
          <el-input maxlength="6" v-model="ruleForm.code" placeholder="请输入国家编码"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input type="text" @input="(e)=>{ruleForm.sort = e.replace(/[^0-9]/g, '')}" maxlength="3" v-model="ruleForm.sort" placeholder="请输入国家排序"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            {{IsAdd?'添加国家':'修改国家'}}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
    CountryList,
    CountryAdd,
    Countrydetail,
    Countryupdate,
    Countrydel
  } from '@/api/xgcApi'

  export default {
    components: {},
    data() {
      return {
        ruleForm:{
          "name": null, //名称
          "sort": null,
          "code": null
        },
        rules:{
          name: [ { required: true, message: '请输入账号', trigger: 'blur' }],
          code: [ { required: true, message: '请输入账号', trigger: 'blur' }],
          sort: [ { required: true, message: '请输入账号', trigger: 'blur' }],
        },
        queryForm: {
          page: 1,
          limit: 20,
        },
        list: [], //国家列表
        total: 0,
        IsAdd: true,
        dialogVisible: false,
        listLoading: false,
      }
    },
    created() {},
    beforeDestroy() {},
    mounted() {
      this.getList()
    },
    methods: {
      // 删除国家
      handleDel(row){
         this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          Countrydel({id:row.id}).then(res=>{
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
            this.handleClose()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      },
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.IsAdd) {
                CountryAdd(this.ruleForm).then(res=>{
                  this.$message({
                    type:"success",
                    message:'添加成功'
                  })
                  this.getList()
                  this.handleClose()
                })
            } else {
              Countryupdate(this.ruleForm).then(res=>{
                  this.$message({
                    type:"success",
                    message:'修改成功'
                  })
                  this.getList()
                  this.handleClose()
                })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handleAdd(type , item=null) {
        this.IsAdd = type
        if(type == false){
          this.listLoading = true
          Countrydetail({id:item.id}).then(res=>{
              this.listLoading = false
              console.log(res)
              this.ruleForm = res.data
          }).catch(err=>{
            this.listLoading = false
          })
        }
        this.dialogVisible = true
      },
      handleClose(){
          this.dialogVisible = false
          this.ruleForm
          this.ruleForm={
            "name": null, //名称
            "sort": null,
            "code": null
          }
      },
      getList() {
        CountryList({}).then(res => {
          this.listLoading = false
          this.list = res.data.list
          this.total = res.data.count
        })
      },
      handleSizeChange(e) {
        this.queryForm.limit = e
        this.getList()
      },
      handleCurrentChange() {
        this.queryForm.page = e
        this.getList()
      }
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .el-switch {
    height: auto !important;
  }
</style>
