<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <!-- 品牌名称 -->
      <el-form-item label="品牌名称" prop="brand_id">
        <el-select
          v-model="form.brand_id"
          filterable
          placeholder="请选择"
          width="500px"
        >
          <el-option
            v-for="item in brandList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- 类型名称 -->
      <el-form-item label="类型名称" prop="name">
        <el-input
          v-model.trim="form.name"
          autocomplete="off"
          placeholder="请输入"
          maxlength="15"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 价格 -->
      <el-form-item label="价格" prop="price">
        <el-input
          v-model.trim="form.price"
          @input="(e)=>{form.price = $geShi(e)}"
          autocomplete="off"
          placeholder="请输入"
          maxlength="15"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 税金 -->
      <el-form-item label="税金" prop="tax">
        <el-input
          v-model.trim="form.tax"
          @input="(e)=>{form.tax = $geShi(e)}"
          autocomplete="off"
          placeholder="请输入"
          maxlength="15"
          show-word-limit
        ></el-input>
      </el-form-item>

      <!-- 序列号 -->
      <el-form-item label="序列号码" prop="code">
        <el-input
          v-model.trim="form.code"
          autocomplete="off"
          placeholder="请输入"
          maxlength="20"
          show-word-limit
        ></el-input>
      </el-form-item>
      <!-- 排序 -->
      <el-form-item label="排序号码" prop="sort">
        <el-input
          v-model.trim="form.sort"
          autocomplete="off"
          placeholder="请输入"
          maxlength="3"
          show-word-limit
          @input="handleInput"
        ></el-input>
      </el-form-item>

      <!-- <el-form-item v-if="isShow" label="分类" prop="type_id">
        <el-radio v-model="radio" label="0">备选项</el-radio>
        <el-radio v-model="radio" label="1">备选项</el-radio>
      </el-form-item> -->
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { valid } from 'mockjs'
  import { typesDetail, typesUpdate, typesAdd } from '@/api/xgcApi'
  import { getBaList } from '@/api/brand.js'

  export default {
    name: 'ManageStore',
    data() {
      return {
        brandList: [],
        form: {},
        rules: {
          name: [{ required: true, trigger: 'blur', message: '请输入名称' }],
          brand_id: [
            { required: true, trigger: 'blur', message: '请输入内容' },
          ],
          price:[{ required: true, trigger: 'blur', message: '请输入内容' }],
          code: [{ required: true, trigger: 'blur', message: '请输入内容' }],
          sort: [{ required: true, trigger: 'blur', message: '请输入内容' }],
          tax: [{ required: true, trigger: 'blur', message: '请输入内容' }],
        },
        title: '',
        dialogFormVisible: false,
        isShow: true,
        radio: '0',
      }
    },
    created() {},
    methods: {
      handleInput() {
        // 移除非数字字符和前导零
        this.form.sort = this.form.sort.replace(/\D/g, '').replace(/^0+/, '')
      },
      async showEdit(row) {
        if (!row) {
          this.title = '添加'
          this.isShow = true
          this.getBrandList()
        } else {
          this.isShow = false
          this.title = '编辑'
          const { data } = await typesDetail({ id: row.id })
          this.form = data
          this.getBrandList()
        }
        this.dialogFormVisible = true
      },
      async getBrandList() {
        const { data } = await getBaList({ id: '' })
        this.brandList = data
      },
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
        this.$emit('fetch-data')
      },
      save() {
        this.form.type_id = this.radio

        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            if (this.isShow == true) {
              const { msg } = await typesAdd(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            } else {
              const { msg } = await typesUpdate(this.form)
              this.$baseMessage(msg, 'success')
              this.$refs['form'].resetFields()
              this.dialogFormVisible = false
              this.$emit('fetch-data')
              this.form = this.$options.data().form
            }
          } else {
            return false
          }
        })
      },
    },
  }
</script>
