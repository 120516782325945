import request from '@/utils/request'
//获得邮费模版集合
export function getPostageList(data) {
  return request({
    url: 'admin/postage/list',
    method: 'post',
    data: data,
  })
}
//通过ID 获得邮费模版的详情
export function postageDetail(data) {
  return request({
    url: 'admin/postage/detail',
    method: 'post',
    data: data,
  })
}
//通过ID 删除邮费模版
export function deleteList(data) {
  return request({
    url: 'admin/postage/del',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data.ids, //ID
    },
  })
}
//修改邮费模板
export function updatePostage(data) {
  return request({
    url: 'admin/postage/update',
    method: 'post',
    // data: data,
    data: {
      token: localStorage.getItem('tokenName'), //token
      id: data.id,
      name: data.name,
      city: data.cityCode, //省份的编码
      type: data.type, //类型 1韩国 2日本
      first_number: data.first_number,
      first_amount: data.first_amount, //首重价格
      repeat_number: data.repeat_number, //续重
      repeat_amount: data.repeat_amount, //续重价格
      kfh_first_number: data.kfh_first_number, //kfh首重
      kfh_first_amount: data.kfh_first_amount, //kfh首重价格
      kfh_repeat_number: data.kfh_repeat_number, //kfh续重
      kfh_repeat_amount: data.kfh_repeat_amount, //khf续重价格
    },
  })
}
//添加邮费模板
export function addList(data) {
  return request({
    url: 'admin/postage/add',
    method: 'post',
    data: {
      token: localStorage.getItem('tokenName'), //token
      name: data.name,
      city: data.cityCode, //省份的编码
      type: data.type, //类型 1韩国 2日本
      first_number: data.first_number,
      first_amount: data.first_amount, //首重价格
      repeat_number: data.repeat_number, //续重
      repeat_amount: data.repeat_amount, //续重价格
      kfh_first_number: data.kfh_first_number, //kfh首重
      kfh_first_amount: data.kfh_first_amount, //kfh首重价格
      kfh_repeat_number: data.kfh_repeat_number, //kfh续重
      kfh_repeat_amount: data.kfh_repeat_amount, //khf续重价格
    },
  })
}
//获得省份集合
export function getCityList(data) {
  return request({
    url: 'index/index/city',
    method: 'post',
    data: {
      pid: '0',
    },
  })
}
