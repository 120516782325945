<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogFormVisible"
    width="600px"
    top="12vh"
    @close="close"
  >
    <!-- 所属用户 -->
    <el-form ref="form" :model="form" label-width="100px">
      <!-- 包裹单号 -->
      <el-form-item label="包裹单号" prop="code">
        <el-input v-model.trim="form.code" :readonly="true"></el-input>
      </el-form-item>
      <!-- 运单号 -->
      <el-form-item label="运单编号" prop="express_code">
        <el-input v-model.trim="form.express_code" :readonly="true"></el-input>
      </el-form-item>

      <!-- 预报商品信息 -->
      <el-form-item label="预报商品信息">
        <div v-for="(item, index) in listInfo" class="xinxi" :key="index">
          名称: {{ item.name }} &nbsp;&nbsp; 数量:{{ item.num }} &nbsp;&nbsp;
          价格:{{ item.price }}
        </div>
      </el-form-item>

      <!-- 预报备注 -->
      <el-form-item label="预报备注" prop="remark">
        <el-input v-model.trim="form.remark" :readonly="true"></el-input>
      </el-form-item>

      <!-- 重量 -->
      <el-form-item label="订单重量" prop="weight">
        <el-input v-model.trim="form.weight" :readonly="true"></el-input>
      </el-form-item>

      <!-- 价格 -->
      <el-form-item label="订单价格" prop="price">
        <el-input v-model.trim="form.price" :readonly="true"></el-input>
      </el-form-item>

      <!-- 申报价格 -->
      <el-form-item label="申报价格" prop="amount">
        <el-input v-model.trim="form.amount" :readonly="true"></el-input>
      </el-form-item>

      <!-- 完善备注 -->
      <el-form-item label="完善备注" prop="remark2">
        <el-input v-model.trim="form.remark2" :readonly="true"></el-input>
      </el-form-item>

      <!-- 用户名 -->
      <el-form-item label="用户名称" prop="name">
        <el-input v-model.trim="form.name" :readonly="true"></el-input>
      </el-form-item>
      <!-- 身份证号 -->

      <!-- 手机号 -->
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model.trim="form.phone" :readonly="true"></el-input>
      </el-form-item>

      <!-- 详细地址 -->
      <el-form-item label="详细地址" prop="dizhi">
        <el-input v-model.trim="form.dizhi" :readonly="true"></el-input>
      </el-form-item>
      <!-- 增值服务信息 -->

      <!-- 开箱方式 -->
      <el-form-item label="开箱方式" prop="shipping_type_name">
        <!-- <el-input
          v-model.trim="form.shipping_type_name"
          :readonly="true"
        ></el-input> -->
        <!-- @change="handleChange2($event, index)" -->
        <el-select
          v-model="form.shipping_type"
          placeholder="请选择"
          :disabled="form.shipping_type==3 || form.shipping_type==4"
        >
          <el-option
            v-for="items in shippingList"
            :key="items.value"
            :label="items.label"
            :value="items.value"
            :disabled="items.disabled"
          ></el-option>
        </el-select>
      </el-form-item>

      <!-- 增值服务信息 -->
      <el-form-item label="增值服务信息">
        <el-select v-model="service_list" multiple placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="`${item.name} （${item.price ? item.price : '-'}）`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <!-- /////////////////////////////- -->
    <div style="text-align: center; margin-bottom: 18px;font-weight: bold;">
      物品明细
    </div>
    <el-form ref="form" :model="form" label-width="100px">
      <div v-for="(item, index) in list">
        <el-form-item label="团名品牌">
          <el-select
            v-model="item.brand_id"
            @change="handleChange($event, index)"
            placeholder="请选择"
          >
            <el-option
              v-for="items in brandList"
              :key="items.id"
              :label="items.name"
              :value="items.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名">
          <el-select
            @change="handleChange2($event, index)"
            v-model="item.b_type_id"
            placeholder="请选择"
          >
            <el-option
              v-for="items in item.brandTypesList"
              :key="items.id"
              :label="items.name"
              :value="items.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model.trim="item.num" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="序列号">
          <el-input
            v-model.trim="item.code"
            style="width: 200px"
            readonly
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            v-if="show"
            icon="el-icon-delete"
            type="danger"
            @click="delItem(index)"
            size="mini"
          >
            删除
          </el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-button
      icon="el-icon-plus"
      type="primary"
      size="mini"
      @click="addItem"
      style="margin-top: 18px; margin-left: 100px"
    >
      添加
    </el-button>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save()">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    orderDetail,
    getBrandList,
    getBrandTypesList,
    orderBrandUpdate,
    serviceIndex
  } from '@/api/xgcApi'

  export default {
    name: 'UserManagementEdit',
    data() {
      return {
        shippingList:[
          {value:1 , label:"原箱" , disabled:false},
          {value:2 , label:"开箱" , disabled:false},
          {value:3 , label:"分箱" , disabled:true},
          {value:4 , label:"合箱" , disabled:true},
          {value:5 , label:"暂存" , disabled:true},
          {value:6 , label:"换箱" , disabled:true},
        ],
        dialogFormVisible: false,
        form: {},
        title: '订单详情',
        listInfo: [],
        service: [],
        brandList: [],
        value: 18,
        type: '',
        brandTypesList: [],
        list: [],
        show: false,
        options: [],
        service_list: [],
      }
    },

    created() {},
    methods: {
      handleChange(value, index) {
        // 处理选中值变化
        this.brandList.forEach((item, ind) => {
          if (value == item.id) {
            this.list[index].brand_name = item.name
          }
        })
        this.getbarndtype(value, index)
        this.list[index].b_type_id = null
        this.list[index].brand_type_name = null
      },
      handleChange2(value, index) {
        // 处理选中值变化
        this.list[index].brandTypesList.forEach((item, ind) => {
          if (value == item.id) {
            this.list[index].brand_type_name = item.name
            this.list[index].code = item.code
          }
        })
      },
      // 获取详情数据
      async showEdit(row) {
        const { data } = await orderDetail({ id: row.id })
        this.form = data
        this.list = data.brand
        this.listInfo = data.item_info
        this.service = data.service


        if (data.service) {
          var arr = []
          data.service.forEach((item) => {
            arr.push(item.sid)
          })
          this.service_list = arr
          serviceIndex({}).then((res)=>{
            this.options = res.data
          })
        }
        if (data.province_name == null) {
          this.form.dizhi = ''
        } else {
          this.form.dizhi =
            data.province_name + data.city_name + data.area_name + data.address
        }
        this.getbarnd()
        data.brand.forEach((item, index) => {
          this.list[index].brandTypesList = [
            {
              brand_id: item.brand_id,
              code: item.code,
              id: item.b_type_id,
              name: item.brand_type_name,
            },
          ]
        })
        this.show1()
        this.dialogFormVisible = true
      },
      // 请求品牌数据
      async getbarnd() {
        getBrandList({}).then(({ data }) => {
          this.brandList = data
        })
      },
      // 请求品牌类型数据
      getbarndtype(e, index) {
        getBrandTypesList({ brand_id: e }).then(({ data }) => {
          this.list[index].brandTypesList = data
          this.$forceUpdate()
        })
      },
      // if列表数量大于1显示删除
      show1() {
        if (this.list.length > 0) {
          this.show = true
        } else {
          this.show = false
        }
      },
      // 添加品牌列表
      addItem() {
        var data = {
          brand_id: '', //品牌id
          b_type_id: '', //品牌下类型（商品id）
          brand_name: '',
          brand_type_name: '',
          num: '',
          code: '',
        }
        this.list.push(data)
        this.show1()
      },
      // 删除列表
      delItem(index) {
        this.list.splice(index, 1)
        this.show1()
      },
      // 点击关闭弹窗
      close() {
        this.$refs['form'].resetFields()
        this.form = this.$options.data().form
        this.dialogFormVisible = false
      },
      // 点击提交更改
      async save() {
        var date = {
          order_id: this.form.id,
          shipping_type:this.form.shipping_type,
          brand_list: this.list,
          service_list: this.service_list.join(',') || 0,
        }
        orderBrandUpdate(date).then(({ data, msg }) => {
          this.brandList = data
          this.$baseMessage(msg, 'success')
          this.$emit('fetch-data' , true)
          this.close()
        })
      },
    },
  }
</script>
<style scoped>
  :deep .el-input--small .el-input__inner {
    width: 435px;
  }
  .black {
    font-weight: bold;
    color: #000000;
  }
  .red {
    color: #ff0000;
  }
  .xinxi {
    border: 1px solid #dcdfe6;
    padding-left: 15px;
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  :deep .el-dialog__body {
    height: 60vh;
    overflow: hidden;
    overflow-y: scroll;
  }
</style>
