<template>
  <div class="table-container">
    <!-- <vab-query-form>
      <vab-query-form-left-panel>
        <el-form
          ref="form"
          :model="queryForm"
          :inline="true"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input v-model="queryForm.name" placeholder="请输入名称" />
          </el-form-item>

          <el-form-item>
            <el-button
              icon="el-icon-search"
              type="primary"
              native-type="submit"
              @click="handleQuery"
            >
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-left-panel>

      <vab-query-form-right-panel>
        <el-button icon="el-icon-plus" type="primary" @click="handleAdd">
          添加
        </el-button>
      </vab-query-form-right-panel>
    </vab-query-form> -->

    <el-table
      ref="tableSort"
      v-loading="listLoading"
      class="table"
      :data="list"
      :element-loading-text="elementLoadingText"
      :height="height"
      border
      @selection-change="setSelectRows"
      @sort-change="tableSortChange"
    >
      <!-- 名称 -->
      <el-table-column
        show-overflow-tooltip
        prop="name"
        label="名称"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="first_number"
        label="YKF首重"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="first_amount"
        label="YKF首重价格"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="repeat_number"
        label="YKF续重"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="repeat_amount"
        label="续重价格(YKF)"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="kfh_first_number"
        label="HJ首重"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="kfh_first_amount"
        label="HJ首重价格"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="kfh_repeat_number"
        label="HJ续重"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        prop="kfh_repeat_amount"
        label="HJ续重价格"
        align="center"
      ></el-table-column>

      <el-table-column
        width="200"
        sortable
        show-overflow-tooltip
        label="创建时间"
        prop="add_time"
        align="center"
      ></el-table-column>

      <el-table-column
        show-overflow-tooltip
        label="操作面板"
        width="130px"
        align="center"
        fixed="right"
      >
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <!-- <el-button type="text" @click="handleDelete(row)">删除</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="position: absolute; left: 0; right: 0; bottom: 20px; margin: auto"
      :background="background"
      :current-page="queryForm.page"
      :layout="layout"
      :page-size="queryForm.limit"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>

<script>
  import { getPostageList, deleteList } from '@/api/postage'
  export default {
    name: 'ComprehensiveTable',
    filters: {
      statusFilter(status) {
        const statusMap = {
          published: 'success',
          draft: 'gray',
          deleted: 'danger',
        }
        return statusMap[status]
      },
    },
    data() {
      return {
        height: '76vh',
        imgShow: true,
        list: [],
        imageList: [],
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        background: true,
        selectRows: '',
        elementLoadingText: '正在加载...',
        queryForm: {
          page: 1,
          limit: 10,
        },
        value1: '',
        value: '',
      }
    },
    // computed: {
    //   height() {
    //     return this.$baseTableHeight()
    //   },
    // },
    created() {
      this.fetchData()
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      tableSortChange() {
        const imageList = []
        this.$refs.tableSort.tableData.forEach((item, index) => {
          imageList.push(item.img)
        })
        this.imageList = imageList
      },
      handleEdit(row) {
        this.$router.push({ path: './edit', query: { id: row.id } })
      },

      setSelectRows(val) {
        this.selectRows = val
      },
      handleAdd() {
        this.$router.push({ path: './add' })
      },
      // handleEdit(row) {
      //   this.$refs['edit'].showEdit(row)
      // },
      handleDelete(row) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await deleteList({ ids: row.id })
          this.$baseMessage(msg, 'success')
          this.fetchData()
        })
      },
      handleSizeChange(val) {
        this.queryForm.limit = val
        this.fetchData()
      },
      handleCurrentChange(val) {
        this.queryForm.page = val
        this.fetchData()
      },
      handleQuery() {
        this.queryForm.page = 1
        this.fetchData()
      },
      async fetchData() {
        this.listLoading = true

        const { data, totalCount } = await getPostageList(this.queryForm)

        this.list = data.list
        this.total = data.count
        // const imageList = []
        // data.forEach((item, index) => {
        //   imageList.push(item.img)
        // })
        // this.imageList = imageList
        // this.total = totalCount
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      },
    },
  }
</script>

<style>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .table {
    margin-top: 30px;
  }
</style>
