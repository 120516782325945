import { render, staticRenderFns } from "./editionlist.vue?vue&type=template&id=030636ef&scoped=true&"
import script from "./editionlist.vue?vue&type=script&lang=js&"
export * from "./editionlist.vue?vue&type=script&lang=js&"
import style0 from "./editionlist.vue?vue&type=style&index=0&id=030636ef&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030636ef",
  null
  
)

export default component.exports