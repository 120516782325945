<template>
  <div v-loading="listLoading" class="table-container">
    <el-table ref="table" :data="list" border>
      <el-table-column align="center" prop="name" label="系统" min-width="180"></el-table-column>
      <el-table-column align="center" prop="version_number" label="版本号" min-width="180"></el-table-column>
      <el-table-column align="center" prop="update_time" label="修改时间" min-width="180"></el-table-column>
      <el-table-column align="center" label="操作" min-width="180">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleAdd(false, row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加修改侧边栏对话框 -->
    <el-dialog :title="IsAdd ? '添加国家' : '修改国家'" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <el-form v-if="dialogVisible" ref="ruleForm" :model="ruleForm" :rules="rules" label-width="150px"
        class="demo-ruleForm">
        <el-form-item label="系统" prop="name">
          <el-input maxlength="20" disabled v-model="ruleForm.name" placeholder="请设置"></el-input>
        </el-form-item>
        <el-form-item label="版本号" prop="version_number">
          <el-input maxlength="20"  v-model="ruleForm.version_number" placeholder="请设置"></el-input>
        </el-form-item>
        <el-form-item label="更新地址" prop="name">
          <el-input maxlength="20"  v-model="ruleForm.address" placeholder="请设置"></el-input>
        </el-form-item>
        <el-form-item label="是否强制更新" prop="edition_force">
           <el-radio-group v-model="ruleForm.edition_force">
             <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="更新方式" prop="name">
          <el-radio-group v-model="ruleForm.package_type">
             <el-radio :label="0">热更新</el-radio>
             <el-radio :label="1">整包更新</el-radio>
           </el-radio-group>
        </el-form-item>
        <el-form-item label="是否静默更新" prop="name">
          <el-radio-group v-model="ruleForm.edition_silence">
            <el-radio :label="1">是</el-radio>
             <el-radio :label="0">否</el-radio>
           </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            修改
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
  import {
   AppList,
   Appdetail,
   Appupdate
  } from '@/api/xgcApi'

  export default {
    components: {},
    data() {
      return {
        ruleForm:{
          "name": "",
          "add_time": null,
          "update_time": "",
          "edition_force": 1, //是否强制更新0否 1是
          "package_type": 0, //0热更新 1整包更新
          "edition_silence": 1, //是否静默1静默0否
          "address": "", //地址
          "version_number": "" //版本号
        },
        rules:{
          name: [ { required: true, message: '请输入手机系统', trigger: 'blur' }],
          version_number: [ { required: true, message: '请输版本号', trigger: 'blur' }],
          edition_force: [ { required: true, message: '是否强制更新', trigger: 'blur' }],
          package_type: [ { required: true, message: '请选择更新方式', trigger: 'blur' }],
          edition_silence: [ { required: true, message: '请选择是否静默更新', trigger: 'blur' }],
          address: [ { required: true, message: '请输入更新地址', trigger: 'blur' }],
        },
        queryForm: {
          page: 1,
          limit: 20,
        },
        list: [], //国家列表
        total: 0,
        IsAdd: true,
        dialogVisible: false,
        listLoading: false,
      }
    },
    created() {},
    beforeDestroy() {},
    mounted() {
      this.getList()
    },
    methods: {
      // 删除国家
      handleDel(row){
         this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          Countrydel({id:row.id}).then(res=>{
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
            this.handleClose()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      },
      submitForm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            Appupdate(this.ruleForm).then(res=>{
                this.$message({
                  type:"success",
                  message:'修改成功'
                })
                this.getList()
                this.handleClose()
              })
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      handleAdd(type , item=null) {
        this.IsAdd = type
        if(type == false){
          this.listLoading = true
          Appdetail({id:item.id}).then(res=>{
              this.listLoading = false
              this.ruleForm = res.data
          }).catch(err=>{
            this.listLoading = false
          })
        }
        this.dialogVisible = true
      },
      handleClose(){
          this.dialogVisible = false
          this.ruleForm
          this.ruleForm={
            "name": null, //名称
            "sort": null,
            "code": null
          }
      },
      getList() {
        AppList({}).then(res => {
          this.listLoading = false
          this.list = res.data.list
          this.total = res.data.count
        })
      },
      handleSizeChange(e) {
        this.queryForm.limit = e
        this.getList()
      },
      handleCurrentChange() {
        this.queryForm.page = e
        this.getList()
      }
    },
  }
</script>

<style scoped>
  :deep .right-panel {
    display: flex;
    align-items: center;
  }

  .el-switch {
    height: auto !important;
  }
</style>
