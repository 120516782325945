<template>
  <div class="app-container">
    <h4 class="form-header h4">新增配送邮费模版</h4>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="120px"
      style="padding-left: 24px"
    >
      <el-form-item label="邮费模版名称" prop="name">
        <el-input
          v-model="form.name"
          placeholder="请输入名称"
          style="width: 240px"
        />
      </el-form-item>

      <!-- <el-form-item label="国家" prop="type">
        <template>
          <el-radio v-model="form.type" :label="1">韩国</el-radio>
          <el-radio v-model="form.type" :label="2">日本</el-radio>
        </template>
      </el-form-item> -->

      <el-form-item label="选择国家">
        <template>
          <el-select
            v-model="city"
            style="width: 240px"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in cityList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </template>
      </el-form-item>

      <el-form-item label="首重(kg)" prop="first_number">
        <el-input
          v-model="form.first_number"
          placeholder="请输入首重"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item label="首重价格(元)" prop="first_amount">
        <el-input
          v-model="form.first_amount"
          placeholder="请输入首重价格"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item label="续重(kg)" prop="repeat_number">
        <el-input
          v-model="form.repeat_number"
          placeholder="请输入续重"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item label="续重价格(元)" prop="repeat_amount">
        <el-input
          v-model="form.repeat_amount"
          placeholder="请输入续重价格"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item label="khf首重(kg)" prop="kfh_first_number">
        <el-input
          v-model="form.kfh_first_number"
          placeholder="请输入khf首重"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item label="khf首重价格(元)" prop="kfh_first_amount">
        <el-input
          v-model="form.kfh_first_amount"
          placeholder="请输入khf首重价格"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item label="khf续重(kg)" prop="kfh_repeat_number">
        <el-input
          v-model="form.kfh_repeat_number"
          placeholder="请输入khf续重"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item label="khf续重价格(元)" prop="kfh_repeat_amount">
        <el-input
          v-model="form.kfh_repeat_amount"
          placeholder="请输入khf续重价格"
          style="width: 240px"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm()">保 存</el-button>
        <el-button @click="close()">返 回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import { addList, getCityList } from '@/api/postage'
  import { CountryList } from '@/api/xgcApi'
  export default {
    data() {
      return {
        cityForm: {},
        cityList: [],
        city: [],
        form: {
          type: '',
          cityCode: [],
        },
        rules: {
          name: [
            {
              required: true,
              message: '邮费模版名称不能为空',
              trigger: 'blur',
            },
          ],
          city: [{ required: true, message: '国加不能为空', trigger: 'blur' }],
          first_number: [
            { required: true, message: '首重不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          first_amount: [
            { required: true, message: '首重价格不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],

          repeat_number: [
            { required: true, message: '续重不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          repeat_amount: [
            { required: true, message: '续重价格不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_first_number: [
            { required: true, message: 'khf首重不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_first_amount: [
            { required: true, message: 'khf首重价格不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_repeat_number: [
            { required: true, message: 'khf续重不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
          kfh_repeat_amount: [
            { required: true, message: 'khf续重价格不能为空', trigger: 'blur' },
            {
              pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
              message: '请输入数字或小数',
              trigger: 'blur',
            },
          ],
        },
      }
    },
    created() {
      this.list()
    },
    methods: {
      async submitForm() {
        this.$refs['form'].validate(async (valid) => {
          if (valid) {
            this.form.cityCode = this.city.join(',')
            this.form.type = 1
            const { msg } = await addList(this.form)
            this.$baseMessage(msg, 'success')
            this.$refs['form'].resetFields()
            this.form = this.$options.data().form
            this.$router.push({ path: './managePostage' })
          } else {
            return false
          }
        })
      },

      async list() {
        // const { data } = await getCityList(this.cityForm)
        // this.cityList = data
        CountryList({}).then(res=>{
          this.cityList = res.data.list
        })
      },
      close() {
        this.$router.push({ path: './managePostage' })
      },
    },
  }
</script>
<style scoped>
  .form-header {
    margin-top: 0;
    padding-left: 15px;
    box-sizing: border-box;
    height: 50px;
    background-color: rgba(
      128,
      128,
      128,
      0.1
    ); /* 使用rgba设置背景颜色为灰色并设置透明度 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 添加阴影效果，水平偏移为0，垂直偏移为2px，模糊半径为4px */
    line-height: 50px;
    font-size: 16px;
  }
</style>
